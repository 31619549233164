import { createRoot } from "react-dom/client";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { registerServiceWorker, renderIfAuthenticated } from "./bootstrap";

window.BUILD_MODE = process.env.BUILD_MODE;

window.GEOLOCATION = {
	getCurrentPosition: Geolocation.getCurrentPosition,
	watchPosition: Geolocation.watchPosition,
	clearWatch: Geolocation.clearWatch,
};

if (!Capacitor.isNativePlatform()) {
	// TODO: check if necessary
	registerServiceWorker();
}

window.addEventListener("DOMContentLoaded", async (/*event*/) => {
	const root = createRoot(document.getElementById("container")!);

	await renderIfAuthenticated(root, () => import("./index-render"), true);
});
